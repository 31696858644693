import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";
import Header4 from "../components/header/header4";
import HistoMix1 from "../components/histograms/histoMix";

import Histo7 from "../components/histograms/histo7";
import Histo8 from "../components/histograms/histo8";
import PieCenter1 from "../components/histograms/PieCenter1";
import Histo9 from "../components/histograms/histo9";
import Histo10 from "../components/histograms/histo10";
import PieCenter2 from "../components/histograms/PieCenter2";
import MGCaption from "../components/MGCaption";
import PieCenter3 from "../components/histograms/PieCenter3";
import NaviNext from "../components/NaviNext";
import { fInt, fFloat } from "../components/helper";
import SinglePhoto from "../components/SinglePhoto";
import { StaticImage } from "gatsby-plugin-image";
import Short from "../components/short";
import ScrollTo from "../components/lego/scrollto";

const Part3 = () => {
  return (
    <Tail>
      <Hero>
        <h1>
          РАЗДЕЛ 3. <br />
          ИНВЕСТИЦИОННАЯ ДЕЯТЕЛЬНОСТЬ
        </h1>
      </Hero>

      <TextBlock>
        <Header2>3.1. ОСНОВНЫЕ НАПРАВЛЕНИЯ ИНВЕСТИЦИОННОЙ ДЕЯТЕЛЬНОСТИ</Header2>

        <article>
          <SinglePhoto className="md:float-right md:w-[350px] md:mx-5 md:mt-2">
            <StaticImage
              src="../images/part-03/3.1.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </SinglePhoto>

          <p className="mb-1">
            Основными направлениями инвестиционной деятельности Общества
            являются:
          </p>

          <ul className="list-disc list-outside">
            <li>
              <p>реконструкция газовых сетей и сооружений;</p>
            </li>
            <li>
              <p className="mb-1">
                строительство объектов газоснабжения, включающее:
              </p>
              <ul className="list-outside list-[circle] mt-0">
                <li>
                  <p>
                    реализацию мероприятий по развитию газового хозяйства города
                    Москвы на территории Троицкого и Новомосковского
                    административных округов города Москвы;
                  </p>
                </li>
                <li>
                  <p>
                    переустройство инженерных сетей при реализации городских
                    целевых программ по реконструкции объектов
                    дорожно-транспортной сети, развитию Московского
                    метрополитена, благоустройству улиц и городских общественных
                    пространств, реконструкции инфраструктуры Московского
                    железнодорожного транспорта и других проектов;
                  </p>
                </li>
                <li>
                  <p>
                    технологическое присоединение объектов капитального
                    строительства к сетям газоснабжения.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </article>
        <Header2>
          3.2. ОБЪЕМ И СТРУКТУРА ИНВЕСТИЦИЙ ПО НАПРАВЛЕНИЯМ ДЕЯТЕЛЬНОСТИ
        </Header2>

        <p>
          Объем инвестиций в основной капитал Общества за последние 5 лет
          ежегодно увеличивается и составил в 2021 году 9783 млн руб.
        </p>
      </TextBlock>
      <ScrollTo className="min-w-[500px]">
        <MGCaption>Объемы капитальных вложений, млн руб.</MGCaption>
        <HistoMix1 idkey="histoMix"></HistoMix1>
      </ScrollTo>
      <TextBlock>
        <p>
          95% от общего объема капитальных вложений Общества составляют
          реконструкция и строительство газовых сетей и сооружений.
        </p>
      </TextBlock>
      <ScrollTo className="min-w-[500px]">
        <MGCaption>
          Динамика капитальных вложений в реконструкцию и строительство газовых
          сетей и сооружений, млн руб.
        </MGCaption>

        <Histo7 idkey={"histo7"}></Histo7>
      </ScrollTo>
      <TextBlock>
        <Header4>ОСНОВНЫЕ НАПРАВЛЕНИЯ ИНВЕСТИЦИЙ В 2021 ГОДУ</Header4>

        <PieCenter1 idkey={"piecent1"}></PieCenter1>

        <MGCaption>
          Динамика строительства и реконструкции газовых сетей в натуральных
          показателях, км
        </MGCaption>

        <Histo8 idkey={"histo8"}></Histo8>

        <p>
          За последние 10 лет уровень износа газовых сетей снижен с 36,5% (на
          31.12.2011) до 27,9%.
        </p>
        <p>
          На 31.12.2021 протяженность подземных сетей со сверхнормативным сроком
          эксплуатации составляет 1116,90 км.
        </p>
        <p>Ввод объектов в эксплуатацию в 2021 году составил 13 080 млн руб.</p>

        <MGCaption>Динамика ввода основных средств, млн руб.</MGCaption>

        <Histo9 idkey={"histo9"}></Histo9>

        <MGCaption>
          Динамика ввода объектов капитального строительства в натуральных
          показателях,км
        </MGCaption>

        <Histo10 idkey={"histo10"}></Histo10>

        <Header2>
          3.3. ИСТОЧНИКИ ФИНАНСИРОВАНИЯ ИНВЕСТИЦИОННОЙ ДЕЯТЕЛЬНОСТИ
        </Header2>

        <MGCaption>
          КАПИТАЛЬНЫЕ ВЛОЖЕНИЯ 2021 ГОДА В РАЗРЕЗЕ ИСТОЧНИКОВ
        </MGCaption>

        <PieCenter2 idkey={"pio2"}></PieCenter2>

        <p>
          Инвестиционная деятельность Общества осуществляется за счет
          собственных источников финансирования (амортизационные отчисления,
          специальная надбавка к тарифу на услуги по транспортировке газа),
          бюджетных средств, а также платы за технологическое присоединение к
          газораспределительным сетям и компенсации по соглашениям на
          переустройство инженерных сетей при городской застройке.
        </p>

        <p>
          В целях реализации комплекса мер, направленных на развитие газового
          хозяйства города Москвы Постановлением Правительства Москвы от
          03.11.2020 №1871-ПП (ред. от 20.07.2021 № 1062-ПП) «Об адресной
          инвестиционной программе города Москвы на 2020–2023 годы» АО«МОСГАЗ»
          установлены следующие объемы бюджетных ассигнований на реализацию
          комплекса мер по развитию газового хозяйства города Москвы:
        </p>

        <MGCaption>
          Лимиты бюджетных ассигнований, установленные Адресной инвестиционной
          программой города Москвы, млн руб.
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td className="sm:w-[350px] md:w-[450px]">
                Наименование мероприятий
              </td>
              <td>
                2021
                <br />
                ПЛАН
              </td>
              <td>
                Поступило в <br />
                распоряжение <br />
                Общества в <br />
                2021 году
              </td>
              <td>Дата поступления</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Мероприятия по газоснабжению территории Троицкого и
                Новомосковского округов города Москвы
              </td>
              <td>{fInt(1640.0)}</td>
              <td>{fInt(1640.0)}</td>
              <td>16.09.2021</td>
            </tr>

            <tr>
              <td>Мероприятия по перекладке газопроводов-дюкеров</td>
              <td>{fInt(728.7)}</td>
              <td>{fInt(728.7)}</td>
              <td>16.09.2021</td>
            </tr>

            <tr>
              <td>
                Перекладка двух подводящих газопроводов высокого давления
                диаметром 700–1000 мм к ТЭЦ-27
              </td>
              <td>{fInt(196.0)}</td>
              <td>{fInt(196.0)}</td>
              <td>13.05.2021</td>
            </tr>

            <tr>
              <td>
                Вынос магистрального газопровода, попадающего в зону
                строительства объектов улично-дорожной сети на территории
                застройки «ЗИЛ-Юг»
              </td>
              <td>{fInt(199.9)}</td>
              <td> </td>
              <td> </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Итого:</td>
              <td>{fInt(2764.6)}</td>
              <td>{fInt(2564.7)}</td>
              <td> </td>
            </tr>
          </tfoot>
        </table>

        <Header2>
          3.4 РЕАЛИЗАЦИЯ ПРОГРАММЫ РЕКОНСТРУКЦИИ и СТРОИТЕЛЬСТВА (без учета
          технологических присоединений к сетям газопотребления объектов
          капитального строительства и переустройства инженерных сетей при
          городской застройке)
        </Header2>

        <p>
          Производственная программа АО «МОСГАЗ» на 2021 год утверждена Советом
          директоров Общества (протокол от 14.01.2021 № 121).
        </p>

        <MGCaption>
          Выполнение производственной программы в части реконструкции и
          строительства за 2021 год, млн руб.
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td className="sm:w-[350px] md:w-[450px]" rowSpan={2}>
                Вид работ/объект
              </td>
              <td rowSpan={2}>
                Ед. измерения <br />
                натуральных <br />
                показателей
              </td>
              <td colSpan={2}>Выполнено</td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>Стоимость</td>
            </tr>
          </thead>
          <tbody>
            <tr className="grayLight">
              <td colSpan={3}>
                Реконструкция и строительство газовых сетей и сооружений на них
              </td>
              <td>{fInt(6532.44)}</td>
            </tr>

            <tr>
              <td colSpan={3}>Реконструкция и строительство</td>
              <td>{fInt(5776.37)}</td>
            </tr>

            <tr>
              <td>Газопроводы</td>
              <td>км</td>
              <td>{fInt(84.18)}</td>
              <td>{fInt(5031.47)}</td>
            </tr>

            <tr>
              <td>ГРП</td>
              <td>шт.</td>
              <td>{fInt(11)}</td>
              <td>{fInt(439.58)}</td>
            </tr>

            <tr>
              <td>Электрозащитные установки</td>
              <td>шт.</td>
              <td>{fInt(48)}</td>
              <td>{fFloat(149.4)}</td>
            </tr>

            <tr>
              <td>АСДУЗУ</td>
              <td>шт.</td>
              <td>{fInt(78)}</td>
              <td>{fInt(135.65)}</td>
            </tr>

            <tr>
              <td>АСУРГАЗ</td>
              <td>шт.</td>
              <td>{fInt(11)}</td>
              <td>{fInt(5.74)}</td>
            </tr>

            <tr>
              <td>ШБДГ</td>
              <td>шт.</td>
              <td>{fInt(42)}</td>
              <td>{fInt(14.54)}</td>
            </tr>

            <tr className="grayLight">
              <td colSpan={3}>Разработка проектно-сметной документации</td>
              <td>{fInt(756.07)}</td>
            </tr>

            <tr>
              <td>Газопроводы</td>
              <td>км</td>
              <td>{fInt(156.1)}</td>
              <td>{fInt(627.88)}</td>
            </tr>

            <tr>
              <td>ГРП</td>
              <td>шт.</td>
              <td>{fInt(13)}</td>
              <td>{fFloat(49.7)}</td>
            </tr>

            <tr>
              <td>Электрозащитные установки</td>
              <td>шт.</td>
              <td>{fInt(72)}</td>
              <td>{fInt(55.57)}</td>
            </tr>

            <tr>
              <td>АСДУЗУ</td>
              <td>шт.</td>
              <td>{fInt(55)}</td>
              <td>{fInt(21.58)}</td>
            </tr>

            <tr>
              <td>АСУРГАЗ</td>
              <td>шт.</td>
              <td>{fInt(4)}</td>
              <td>{fInt(1.34)}</td>
            </tr>

            <tr className="grayLight">
              <td colSpan={3}>
                Реконструкция производственно-административных зданий
              </td>
              <td>{fInt(56.57)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>Итого:</td>
              <td>{fInt(6589.02)}</td>
            </tr>
          </tfoot>
        </table>

        <p className="mb-1">
          Производственная программа Общества по реконструкции и строительству
          газовых сетей и сооружений за 2021 год выполнена в объеме 6589 млн
          руб., включая:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              прокладку газовых сетей общей протяженностью 84,18 км, из которых
              49,95 км – реконструкция; 34,23 км – новое строительство;
            </p>
          </li>
          <li>
            <p>
              строительство и ввод в эксплуатацию системообразующего ГРП
              «Ликовский» (территория ТиНАО);
            </p>
          </li>
          <li>
            <p>
              реконструкцию ГРП № М-5 «Люблино 1» средней производительности;
            </p>
          </li>

          <li>
            <p>
              модернизацию 9 ГРП малой производительности с применением
              модульных блоков полной заводской готовности;
            </p>
          </li>

          <li>
            <p>
              установку автоматизированной системы дистанционного управления
              запорными устройствами на 78 объектах;
            </p>
          </li>
          <li>
            <p>
              разработку проектно-сметной документации на строительство и
              реконструкцию газопроводов общей протяженностью 156,1 км, 13 ГРП,
              72 ЭЗУ, 55 АСДУЗУ.
            </p>
          </li>
        </ul>

        <SinglePhoto>
          <StaticImage
            src="../images/part-03/3.4.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <Header4>Ключевые объекты строительства</Header4>

        <p>
          Реконструирован ГРП средней производительности 14.025{" "}
          <strong>ГРП №М-5 «Люблино 1»</strong>, расположенный по адресу: г.
          Москва, ЮВАО, ул. Заречье, д. 5А. ГРП предназначен для газоснабжения
          социальных, промышленных объектов и объектов энергетики, а также на
          нужды жилого фонда ЮВАО города Москвы через газорегуляторные пункты
          Краснодонский и Ставропольский. В результате реконструкции на ГРП
          «Люблино 1» будет выполняться редуцирование газа с 1,2 МПа до 0,6 и
          0,3 МПа, что приведет к ликвидации трех обособленных зон газопроводов
          давлением 0,6 МПа, проложенных в южной части города. Это завершит
          формирование единой системы транспорта газа высокого давления от
          района Теплый стан до районов Текстильщики и Печатники, опирающейся на
          несколько источников подачи, в том числе на ГРП «Южная ГС»,
          «Карачаровская ГС» и «Люблино 1».
        </p>

        <Header4>
          Выполнение мероприятий по развитию системы газоснабжения на территории
          ТиНАО города Москвы
        </Header4>
        <article>
          <Short
            text="Развитие системы газоснабжения на территории ТиНАО"
            link="https://www.mos-gaz.ru/projects/483"
            short_id="short_3-4-1"
            className="w-[250px]"
            tinao={true}
          />
          <p className="mb-1">
            С начала реализации проекта (с 2018 года) работы по проектированию
            ведутся по всем 21 объектам:
          </p>

          <ul className="list-disc list-outside">
            <li>
              <p>
                отработаны заказы инженерно-топографических изысканий на 329,4
                км;
              </p>
            </li>
            <li>
              <p>
                заказаны инженерно-геологические/экологические изыскания под
                строительство газопроводов общей протяженностью 302,52 км;
              </p>
            </li>
            <li>
              <p>
                получены согласования ОПС ГБУ «Мосгоргеотрест» на участки
                газопровода протяженностью 185 км;
              </p>
            </li>
            <li>
              <p>
                получены положительные заключения ГАУ «Мосгосэкпертиза» на
                строительство сети газоснабжения в Филимонковском, Первомайском,
                Михайловском, Кленовском, Вороновском поселениях ТиНАО общей
                протяженностью 97,63 км.
              </p>
            </li>
          </ul>
        </article>
        <p>
          Всего по состоянию на 31.12.2021 на территории ТиНАО построено 83,9 км
          газопроводов высокого давления в поселениях: Филимонковском,
          Первомайском, Михайловском, Вороновском и Кленовском ТиНАО.
        </p>

        <p>
          Завершение этого строительства обеспечивает формирование устойчивой
          системы транспорта газа на территории пяти поселений ТиНАО, а также
          удовлетворение потребности в природном газе Михайлово-Ярцевского
          поселения, включая зону реновации в поселке Шишкин Лес.
        </p>

        <p>
          Построен и введен в эксплуатацию ГРП «Ликовский» с целью обеспечения
          газом существующих и перспективных объектов капитального строительства
          на территории семи поселений ТиНАО: Внуковского, Марушкинского,
          Кокошкино, Первомайского, Филимонковского, Десеновского и Московского.
          Пропускная способность ГРП «Ликовский» составляет 140 тыс. куб. м в
          час. ГРП будет обеспечивать подачу газа по системе газопроводов
          высокого давления к многоэтажной жилой, общественно-деловой и
          производственной застройке ТиНАО суммарной площадью более 7,6 млн кв.
          м. Указанный пункт будет обеспечивать подачу газа в существующий
          индивидуальный жилой фонд с общим количеством домовладений более 8000.
          С целью обеспечения надежного бесперебойного газоснабжения
          потребителей предусматривается резервирование подачи газа от ГРП
          «Ликовский» за счет закольцовки выходных сетей с ГРП «ГТЭС Внуково».
        </p>

        <Header4>
          Реконструкция 13 газопроводов-дюкеров высокого и среднего давлений
        </Header4>

        <p>
          Проектно-изыскательские работы завершены по 12 объектам, положительные
          заключения государственной экспертизы получены по 10 объектам,
          проектная документация по 2 объектам подготовлена для прохождения
          экспертизы, 1 объект (Кропоткинский дюкер) в проектировании.
        </p>

        <p>
          Завершено строительство и введено в эксплуатацию 6 газопроводов-
          дюкеров: Автозаводский, Даниловский, Химкинский, Новодевичий,
          Тушинский, Рубцовский.
        </p>

        <p>
          Работы продолжаются на 4 газопроводах-дюкерах: Щукинском, Кутузовском,
          Гольяновском, Фрунзенском. Врезка их в газораспределительную сеть
          города Москвы запланирована на 2022–2023 годы.
        </p>

        <p>
          Начало строительно-монтажных работ на Голутвинском, Кропоткинском и
          Клязьменском газопроводах-дюкерах – 2023–2024 годы.
        </p>

        <p>Всего проложено (реконструировано) 4,8 км газопроводов-дюкеров.</p>

        <Header4>
          Реализация мероприятий по газификации поселка Толстопальцево
        </Header4>
        <article>
          <p>
            С начала реализации проекта построено 52,4 км газовых сетей, включая
            два газорегуляторных пункта ГРПБ «Заря» и ГРПБ «Поляны», установлено
            3 шкафных распределительных пункта.
          </p>

          <p>
            В целом мероприятия по газификации поселка Толстопальцево завершены.
            В 2022 году осталось выполнить работы по благоустройству в летнем
            варианте.
          </p>
          <Short
            text="Информация для жителей Толстопальцево"
            link="https://www.mos-gaz.ru/individuals/service/informaciya-dlya-zhiteley-tolstopalcevo"
            short_id="short_3-4-2"
            className="w-[250px]"
          />
          <p>
            Проект по завершению газификации поселка Толстопальцево ЗАО Москвы
            предусматривает строительство сети газопроводов высокого, среднего и
            низкого давлений к самому поселку, а также по его территории и по
            территории смежных населенных пунктов и НКО. При этом подводящий
            газопровод высокого давления Ø500 мм ПЭ, подключенный к ГРП «ГТЭС
            Внуково», является частью системообразующего газопровода,
            прокладываемого по территории ТиНАО в рамках объекта № 18.001 по
            Программе газификации. Реализация этого проекта позволила снять все
            ограничения по подключению жилых домов, промышленных и
            коммунально-бытовых объектов в зоне поселка Толстопальцево, также
            создала условия для подключения перспективной многоэтажной
            комплексной застройки ООО «Трансстройинвест Девелопмент» возле
            деревни Постниково.
          </p>

          <p>
            Строительство ГРПБ «Заря» и ГРПБ «Поляны» позволило создать условия
            для газификации всего жилого сектора с общим количеством
            негазифицированных домовладений более 2000, а также создало
            возможность переключить АО «Внуковский авиаремонтный завод ВАРЗ-400»
            и СНТ «Толстопальцево-5» на новые источники подачи газа с
            минимальными затратами.
          </p>

          <p>
            Суммарное перспективное потребление газа в зоне реализации проекта
            составит 56 млн куб. м в год, включая существующее.
          </p>
        </article>
        <Header2>
          3.5. ТЕХНОЛОГИЧЕСКИЕ ПРИСОЕДИНЕНИЯ К СЕТЯМ ГАЗОПОТРЕБЛЕНИЯ ОБЪЕКТОВ
          КАПИТАЛЬНОГО СТРОИТЕЛЬСТВА И ПЕРЕУСТРОЙСТВО ИНЖЕНЕРНЫХ СЕТЕЙ ПРИ
          ГОРОДСКОЙ ЗАСТРОЙКЕ
        </Header2>
        <article>
          <p className="mb-1">
            В 2021 году выполнено подключение (технологическое присоединение) к
            сетям газораспределения 325объектов капитального строительства с
            общим расходом газа 32 235 м3/ч, в том числе:
          </p>
          <Short
            text="Технологическое присоединение"
            link="https://www.mos-gaz.ru/business/service/tekhnologicheskoe-prisoedinenie-k-gazovym-setyam"
            short_id="short_3-5"
            className="w-[250px]"
          />
          <ul className="list-disc list-outside">
            <li>
              <p>
                1-я группа потребителей (заявители Льготный категории с МЧРГ до
                15 м3/ч) – 123 объекта;
              </p>
            </li>
            <li>
              <p>
                2-я группа потребителей (по стандартизированным тарифным
                ставкам) – 198 объектов;
              </p>
            </li>
            <li>
              <p>
                3-я группа потребителей (по индивидуальным проектам) – 4
                объекта.
              </p>
            </li>
          </ul>

          <p>
            В рамках реализованных договоров на подключение более 68%
            потребителей 1-й категории располагаются на территории ТиНАО и в
            поселке Толстопальцево. Это обусловлено строительством АО «МОСГАЗ»
            сети газопроводов, где наблюдался острый дефицит пропускной
            способности существующих сетей и источников. По мере реализации
            проектов в АО «МОСГАЗ» увеличивается количество поступающих заявок
            на подключение, поскольку затраты потребителей в этом случае
            минимальны. Также реализация проектов по строительству
            системообразующих газопроводов позволила реализовать подключение
            новой квартальной котельной в Филимонковском поселении НАО и
            заключить договоры на газификацию еще нескольких крупных объектов
            энергетики в зоне строительства объекта 18.001.
          </p>

          <p>
            По состоянию на 01.01.2022 года в работе находятся 1038договоров на
            технологическое присоединение с общим расходом газа 216 723м3/ч.,
            для исполнения которых необходимо построить 44 км газовых сетей.
          </p>
        </article>
        <table className="tBig">
          <thead>
            <tr>
              <td className="sm:w-[350px] md:w-[450px]">Наименование</td>
              <td>Количество</td>
              <td>МЧРГ, м3/ч</td>
              <td>
                Проектная <br />
                протяженность <br />
                газопроводов <br />
                пог. м
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Технологическое присоединение (льготный)</td>
              <td>{fInt(418)}</td>
              <td>{fInt(2163)}</td>
              <td>{fInt(5070)}</td>
            </tr>

            <tr>
              <td>Технологическое присоединение (стандартный)</td>
              <td>{fInt(564)}</td>
              <td>{fInt(78_883)}</td>
              <td>{fInt(9413)}</td>
            </tr>

            <tr>
              <td>Технологическое присоединение (индивидуальный)</td>
              <td>{fInt(56)}</td>
              <td>{fInt(135677)}</td>
              <td>{fInt(29433)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Всего:</td>
              <td>{fInt(1038)}</td>
              <td>{fInt(216723)}</td>
              <td>{fInt(43916)}</td>
            </tr>
          </tfoot>
        </table>

        <p>
          В 2021 году общий объем капитальных вложений на проектирование и
          строительство газовых сетей и сооружений для объектов технологического
          подключения составил 493 243 тыс. руб., построено 7,1 км газовых
          сетей.
        </p>

        <p>
          В 2021 году введен в эксплуатацию газорегуляторный пункт «Митино
          Новый» с производительностью 140 000 м3/ч, что позволило снять
          перегруженность сети газораспределения и обеспечить бесперебойное
          газоснабжение населенных пунктов в районе Митино Северо-Западного
          административного округа Москвы и прилегающих территорий
          Красногорского района Московской области, а также обеспечило
          возможность подключения новых потребителей в указанных районах.
        </p>

        <p>
          АО «МОСГАЗ» принимает непосредственное участие в реализации городских
          программ по развитию улично-дорожной сети и Московского метрополитена,
          благоустройству улиц и городских общественных пространств,
          реконструкции инфраструктуры железнодорожного транспорта и других
          программ столицы.
        </p>

        <p>
          В 2021 году общий объем капитальных вложений на проектирование и
          выполнение комплекса строительно-монтажных работ по ликвидации
          (частичной ликвидации) и восстановлению инженерных сетей и сооружений
          составил 2 306 482 тыс. руб., построено 15,19 км газовых сетей.
        </p>

        <p className="mb-1">
          По состоянию на 01.01.2022 года в работе находятся 194 соглашения о
          порядке осуществления денежной компенсации собственнику инженерных
          сетей и сооружений.
        </p>

        <p className="mb-1">Объем выполненных работ в разрезе заказчиков:</p>
        <ul className="list-disc list-outside">
          <li>
            <p>городской заказ – 10,30 км (68%);</p>
          </li>
          <li>
            <p>частные инвесторы – 4,89 км (32%).</p>
          </li>
        </ul>

        <p className="mb-1">
          Объемы строительства за 2021 год по объектам городского заказа:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>строительство метро – 1,22 км (12%);</p>
          </li>
          <li>
            <p>
              строительство дорожно-транспортной инфраструктуры – 6,75 км (65%);
            </p>
          </li>
          <li>
            <p>
              строительство прочих объектов адресной инвестиционной программы
              города Москвы – 2,33 км (23%).
            </p>
          </li>
        </ul>

        <MGCaption todo="Это заголовок или кепшн к графику?">
          ПЕРЕУСТРОЙСТВО ИНЖЕНЕРНЫХ СЕТЕЙ В РАМКАХ ГОРОДСКОГО ЗАКАЗА В 2021 ГОДУ
        </MGCaption>

        <PieCenter3 idkey={"pie3"}></PieCenter3>

        <p className="mb-1">
          В рамках реализации программы города Москвы по развитию транспортной
          системы в отчетном году Обществом выполнялись работы по перекладке
          газопроводов на 3 площадках строящихся линий метрополитена общей
          протяженностью 1,22 км:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              «Южный участок третьего пересадочного контура ст. «Каховская» –
              ст. «Проспект Вернадского». Подготовка территории для
              строительства ст. «Зюзино». Площадка № 6»;
            </p>
          </li>
          <li>
            <p>
              «Транспортно-пересадочный узел на ст. «Лефортово». Этап 1:
              «Технологическая часть транспортно-пересадочного узла на станции
              метро «Лефортово». Реконструкция улично-дорожной сети. Вынос сетей
              газопровода, адресный ориентир: г. Москва, ул. Солдатская, д. 10,
              к. 2 – д. 14»;
            </p>
          </li>
          <li>
            <p>
              «Линия метрополитена от станции «Новаторов» до станции метро
              «Севастопольский проспект». Этап: «Подготовка территории». Этап
              2.2: Площадка № 2. Станция «Улица Строителей». Вынос сетей
              газопровода по адресу: г. Москва, ул. Строителей – пр.
              Вернадского, д. 11».
            </p>
          </li>
        </ul>

        <p className="mb-1">
          В рамках городской программы развития улично-дорожной сети,
          строительства Юго-Восточной и Северо-Восточной хорд переложено 6,75 км
          газопроводов. Работы выполнялись на 19 площадках выноса газопровода из
          зоны строительства автодорог и транспортных развязок, включая наиболее
          значимые объекты:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              «Участок от улицы Маршала Шестопалова до Павелецкого направления
              Московской железной дороги» (Юго-Восточная хорда. Участок № 8)» –
              2,09 км;
            </p>
          </li>
          <li>
            <p>
              «Строительство улично-дорожной сети с искусственными сооружениями
              и переустройством инженерных коммуникаций на участке от МКАД до
              автомобильной дороги «Солнцево–Бутово– Варшавское шоссе» 10-й
              участок ЮВХ» – 1,13 км;
            </p>
          </li>
          <li>
            <p>
              «Северо-Восточная хорда. Участок от Щелковского шоссе до Открытого
              шоссе» – 0,84 км;
            </p>
          </li>
          <li>
            <p>
              «Строительство эстакад основного хода, многоуровневых транспортных
              развязок в районе станции метро «Владыкино», станции метро
              «Ботанический сад», эстакад-съездов Ярославского шоссе, мостов
              через р. Яуза, р. Лихоборка, переустройство инженерных сетей и
              коммуникаций со строительством необходимых для их функционирования
              подъездных дорог на участке от Ярославского шоссе до Дмитровского
              шоссе» – 0,74 км;
            </p>
          </li>
          <li>
            <p>
              «Строительство путепроводов на пересечении Октябрьского
              направления Московской железной дороги и Савеловского направления
              Московской железной дороги на участке от Б. Академической ул. до
              ул. Академика Королева с необходимой для их функционирования
              улично-дорожной сетью» – 0,56 км;
            </p>
          </li>
          <li>
            <p>
              «Путепровод через железнодорожные пути Смоленского направления
              Московской железной дороги, внеуличные пешеходные переходы.
              Переустройство инженерных сетей и коммуникаций, в т.ч.
              железнодорожная инфраструктура, с реконструкцией ул. Барклая,
              Промышленного и Багратионовского проездов, обеспечивающих их
              функционирование» – 0,27 км;
            </p>
          </li>
          <li>
            <p>
              «Участок от ул. Каспийской до 1-го Котляковского переулка» (Южная
              рокада. Участок № 1)» – 0, 25 км.
            </p>
          </li>
        </ul>

        <SinglePhoto>
          <StaticImage
            src="../images/part-03/3.5.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p className="mb-1">
          В целях подготовки территорий для сноса ветхого малоэтажного жилого
          фонда (программа Фонда реновации) за отчетный период выполнены работы
          по ликвидации и восстановлению газопроводов общей протяженностью 2,33
          км:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              «Жилой дом с инженерными сетями и благоустройством территории по
              адресу: г. Москва, район Люблино, ул. Люблинская, вл. 111, к. 2
              (Юго-Восточный административный округ)» – 0,57 км;
            </p>
          </li>
          <li>
            <p>
              «Снос существующего строения, отключение и перекладка инженерных
              коммуникаций сносимого здания, расположенного по адресу: г.
              Москва, район Котловка, улица Ремизова, д. 11, корп. 2 (Юго-
              Западный административный округ)» – 0,2 км;
            </p>
          </li>
          <li>
            <p>
              «Снос существующих строений, отключение и перекладка инженерных
              коммуникаций сносимых зданий, расположенных по адресу: г. Москва,
              район Академический, Новочеремушкинская ул., д. 27 и д. 29
              (Юго-Западный административный округ)» – 0,25 км;
            </p>
          </li>
          <li>
            <p>
              «Жилой дом по адресу: г. Москва, район Южное Тушино, мкр. 11,
              корп. 1» – 0,19 км.
            </p>
          </li>
        </ul>
        <NaviNext
          next="/part-4"
          title="РАЗДЕЛ 4. ОСНОВНЫЕ ПОКАЗАТЕЛИ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ"
        ></NaviNext>
      </TextBlock>
    </Tail>
  );
};

export default Part3;

export const Head = () => <Seo title="РАЗДЕЛ 3. ИНВЕСТИЦИОННАЯ ДЕЯТЕЛЬНОСТЬ" />;
