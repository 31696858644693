import * as React from "react"
import * as d3 from "d3"

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
})

const Histo7 = ({ idkey }) => {
  const data = [
    {
      date: "2017",

      all: 4887,
      serv: 3891,
      new: 996,
    },
    {
      date: "2018",

      all: 6297,
      serv: 3957,
      new: 2340,
    },
    {
      date: "2019",

      all: 7213,
      serv: 3458,
      new: 3755,
    },
    {
      date: "2020",

      all: 8241,
      serv: 3622,
      new: 4619,
    },
    {
      date: "2021",

      all: 9334,
      serv: 3838,
      new: 5496,
    },
  ]

  const margin = { top: 70, right: 30, bottom: 50, left: 100 },
    width = 750 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["all", "serv", "new"]
    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)")
      .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 10_000]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(locale.format("$,")))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#aaa", "#ccc", "#FFA500", "#cb953e"])

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          return { key: key, value: d[key] }
        })
      })
      .join("text")
      .attr("x", d => {
        if (d.value < 40000) {
          return xSubgroup(d.key) + 15
        }

        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value) + 8
      })
      .text(d => locale.format("$,")(d.value)) // FIXME: обновить локаль
      .style("font-size", "12px")
      .style("fill", d => {
        return "black"
      })
      .style("font-weight", "bold")
      .attr("text-anchor", d => {
        return "start"
      })
      .attr("transform", d => {
        return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
      })
  }, [idkey])

  const ldata = [
    {
      label:
        "Капитальные вложения в реконструкцию и строительство газовых сетей и сооружений, в том числе",
      color: "",
    },
    {
      label: "реконструкция",
      color: "",
    },
    {
      label: "строительство",
      color: "",
    },
  ]
  const lcolor = ["bg-[#aaa]", "bg-[#ccc]", "bg-[#FFA500]", "bg-[#cb953e]"]
  const getLegendColor = getNextColor(lcolor)

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${lcolor[i]}`}></span>
              {el.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Histo7
