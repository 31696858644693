import * as React from "react";
import * as d3 from "d3";
import { fIntG, Colors2 } from "../helper";

const cachecolor = ["bg-[#ccc]", "bg-[#ffa500]"];

const HistoMix1 = ({ idkey }) => {
  const maxY = 10_000;
  const data = [
    {
      date: "2017",

      all: 5895,
      // serv: 3657.03,
      p: 83.0,
    },
    {
      date: "2018",

      all: 7552,
      // serv: 3609.88,
      p: 83.0,
    },
    {
      date: "2019",

      all: 7819,
      // serv: 3574.4,
      p: 92.0,
    },
    {
      date: "2020",

      all: 9290,
      // serv: 2731.83,
      p: 89.0,
    },
    {
      date: "2021",

      all: 9783,
      // serv: 1645.9,
      p: 95.0,
    },
  ];

  const margin = { top: 70, right: 30, bottom: 50, left: 100 },
    width = 450 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom;

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`;
    document.querySelector(selector).innerHTML = "";
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const subgroups = ["all"];
    var groups = d3.map(data, (d) => {
      return d.date;
    });

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end");

    var y = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG));

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05]);

    var color = d3.scaleOrdinal().domain(subgroups).range(Colors2);

    const g = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => {
        return "translate(" + x(d.date) + ",0)";
      })

      .selectAll("rect")
      .data((d) => {
        return subgroups.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", (d) => {
        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(d.value);
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => {
        return height - y(d.value);
      })
      .attr("fill", (d) => {
        return color(d.key);
      })
      .exit()
      .data((d) => {
        return subgroups.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .join("text")
      .attr("x", (d) => {
        if (d.value < 40000) {
          return xSubgroup(d.key) + 27;
        }

        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(25) + 12;
      })
      .text((d) => fIntG(d.value))
      .style("font-size", "12px")
      .style("fill", (d) => {
        // if (d.value < 40000) {
        //   return "black"
        // }
        return "black";
      })
      .style("font-weight", "bold")
      .attr("text-anchor", (d) => {
        if (d.value < 40000) {
          return "start";
        }
        return "end";
      })
      .attr("transform", (d) => {
        return `rotate(-90, ${xSubgroup(d.key) + 15}, ${y(35)})`;
      });
    // .exit()

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#aaa")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveNatural)
          .x((d) => x(d.date) + 25)
          .y((d) => y((d.all / 100) * d.p))
      );

    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .join("circle")
      .attr("class", "myCircle")
      .attr("cx", (d) => x(d.date) + 25)
      .attr("cy", (d) => y((d.all / 100) * d.p))
      .attr("r", 3)
      .attr("stroke", "orange")
      .attr("stroke-width", 3)
      .attr("fill", "white")
      .exit()
      .data(data)
      .join("text")
      .attr("x", (d) => x(d.date) + 13)
      .attr("y", (d) => y((d.all / 100) * d.p) + 22)
      .text((d) => d.p + "%")
      .style("font-size", "12px")
      .attr("fill", "black")
      .style("font-weight", "bold");
  }, [idkey]);

  const ldata = [
    {
      label: "Объемы капитальных вложений, млн.руб.",
      color: "",
    },
    {
      label:
        "Доля работ по строительству и реконструкции сетей в общем объеме капитальных вложений",
      color: "",
    },
  ];

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className={`HistoOld-${idkey}`}></div>
        <div className="max-w-4xl flex justify-center flex-col">
          <ul className="list-none pl-0 text-300 my-5 text-sm">
            {ldata.map((el, i) => (
              <li key={i}>
                <span className={`legend-pseudo ${cachecolor[i]}`}></span>
                {el.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default HistoMix1;
